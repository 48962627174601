import { LpNewToolTip } from '../../../../components/new-tooltip/LpNewToolTip';
import { SortOrder } from '../../../../enums/sort-order.enum';
import ascendingSvg from '../../../../images/ascending.svg';
import descendingSvg from '../../../../images/descending.svg';
import styles from './LpSwipeQuestionSortIcon.module.css';

interface Props {
  onChangeOrder: (sortOrder: SortOrder) => void;
  order: SortOrder;
}

export const LpSwipeQuestionSortIcon = ({ order, onChangeOrder }: Props) => {
  if (order === SortOrder.DESC) {
    return (
      <LpNewToolTip
        showToolTip={true}
        textOnHover="Reverse order"
        containerCustomClass={styles.customContainerClass}
        toolTipTextCustomClass={styles.tooltipTextForSortIcon}
      >
        <img
          src={descendingSvg}
          onClick={() => onChangeOrder(SortOrder.ASC)}
          alt="desc order"
          className={styles.icon}
        />
      </LpNewToolTip>
    );
  }

  return (
    <LpNewToolTip
      showToolTip={true}
      textOnHover="Reverse order"
      toolTipTextCustomClass={styles.tooltipTextForSortIcon}
      containerCustomClass={styles.customContainerClass}
    >
      <img
        src={ascendingSvg}
        onClick={() => onChangeOrder(SortOrder.DESC)}
        alt="asc order"
        className={styles.icon}
      />
    </LpNewToolTip>
  );
};
