import styles from './LpSwipeQuestionToggleForTeams.module.css';
import userGroupSvg from '../../../../images/user-group.svg';
import userGroupWhiteSvg from '../../../../images/user-group-white.svg';
import fourDotsSvg from '../../../../images/four-dots-black.svg';
import fourDotsWhiteSvg from '../../../../images/four-dots-white.svg';
import { SwipeQuestionChartView } from '../../../../enums/teams-chart-view.enum';
import { LpNewToolTip } from '../../../../components/new-tooltip/LpNewToolTip';

interface Props {
  onChangeView: (currentView: SwipeQuestionChartView) => void;
  currentView: SwipeQuestionChartView;
}

export const LpSwipeQuestionToggleForTeams = ({
  onChangeView,
  currentView,
}: Props) => {
  return (
    <div className={styles.toggle}>
      <div
        className={
          currentView === SwipeQuestionChartView.INDIVIDUAL
            ? styles.activeViewForResponse
            : styles.activeViewForCard
        }
      >
        <LpNewToolTip
          textOnHover={
            currentView === SwipeQuestionChartView.INDIVIDUAL
              ? 'Individual view'
              : 'Team view'
          }
          showToolTip={true}
          toolTipTextCustomClass={styles.toolTipTextCustomClass}
        >
          {currentView === SwipeQuestionChartView.INDIVIDUAL ? (
            <img src={fourDotsWhiteSvg} alt="individual view active" />
          ) : (
            <img src={userGroupWhiteSvg} alt="teams view active" />
          )}
        </LpNewToolTip>
      </div>
      <LpNewToolTip
        textOnHover="Individual view"
        showToolTip={true}
        containerCustomClass={styles.tooltipContainerClass}
        toolTipTextCustomClass={styles.toolTipTextCustomImageClass}
      >
        <img
          height={16}
          width={16}
          src={fourDotsSvg}
          onClick={() => onChangeView(SwipeQuestionChartView.INDIVIDUAL)}
          alt="disable individual view"
          className={styles.icon}
        />
      </LpNewToolTip>

      <LpNewToolTip
        textOnHover="Team view"
        showToolTip={true}
        containerCustomClass={styles.tooltipContainerClass}
        toolTipTextCustomClass={styles.toolTipTextCardOrderCustomClass}
      >
        <img
          height={16}
          width={16}
          src={userGroupSvg}
          onClick={() => onChangeView(SwipeQuestionChartView.TEAMS)}
          alt="disable teams view"
          className={styles.icon}
        />
      </LpNewToolTip>
    </div>
  );
};
