import { useEffect, useState } from 'react';
import { SwipeQuestionCard } from 'src/interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from 'src/interfaces/swipe-question-option.interface';
import styles from './LpSwipeQuestionChartForPoll.module.css';
import ribbonSvg from '../../../images/blue-ribbon.svg';
import greyRibbonSvg from '../../../images/grey-ribbon.svg';
import whiteRibbonSvg from '../../../images/white-ribbon.svg';
import clockSvg from '../../../images/clock.svg';
import whiteClockSvg from '../../../images/white-clock.svg';
import ascendingSvg from '../../../images/ascending.svg';
import descendingSvg from '../../../images/descending.svg';
import swipeLeftSvg from '../../../images/swipe-left.svg';
import swipeRightSvg from '../../../images/swipe-right.svg';
import { LpNewToolTip } from '../../../components/new-tooltip/LpNewToolTip';
import { ToolTipMarkerPositionEnum } from '../../../enums/tooltip-marker-position.enum';
import { SwipeQuestionFinishDataWithRank } from '../../../interfaces/swipe-question-finish-data-with-rank.interface';
import { ChartView } from '../../../enums/chart-view.enum';
import { SortOrder } from '../../../enums/sort-order.enum';
import { SwipeQuestionResponse } from 'src/interfaces/swipe-question-response.interface';

interface Props {
  swipeQuestion: {
    swipeOptions: SwipeQuestionOption;
    cards: SwipeQuestionCard[];
  };
  responses: SwipeQuestionResponse[];
  customCardRowClass?: string;
  customHeaderRowClass?: string;
  customContainerClass?: string;
  isReportScreen?: boolean;
}

export const LpSwipeQuestionChartForPoll = ({
  swipeQuestion,
  customCardRowClass,
  customHeaderRowClass,
  customContainerClass,
  responses,
  isReportScreen,
}: Props) => {
  const [sortedResponsesWithRank, setSortedResponsesWithRank] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);

  const [updatedSortedResponsesWithRank, setUpdatedSortedResponsesWithRank] =
    useState<SwipeQuestionFinishDataWithRank[]>([]);

  const [currentView, setCurrentView] = useState<ChartView>(
    ChartView.RIGHT_SWIPES_RESPONSE_RATE,
  );

  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const leftSideColor = '#FF7681';
  const leftSideText = swipeQuestion.swipeOptions.left.text;
  const rightSideText = swipeQuestion.swipeOptions.right.text;
  const rightSideColor = '#3FCA7F';
  const cards = swipeQuestion.cards;
  const lastRank = cards.length;

  useEffect(() => {
    const prepareSwipeQuestionResult = (
      swipeQuestionResponses?: SwipeQuestionResponse[],
    ) => {
      if (!swipeQuestionResponses) {
        return [];
      }
      const rankedResponses = swipeQuestionResponses
        .map(item => ({
          ...item,
          percentage: getPercentage(
            Number(item.rightCount),
            Number(item.leftCount),
          ),
        }))
        .sort((a, b) => Number(b.percentage) - Number(a.percentage))
        .map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

      const cardIdsWithResponses = new Set(
        swipeQuestionResponses.map(r => r.cardId),
      );
      const cardsWithoutResponses = cards.filter(
        card => !cardIdsWithResponses.has(card.id),
      );

      const lastRank = rankedResponses.length;
      cardsWithoutResponses.forEach((card, index) => {
        const currentIndex = index + 1;
        rankedResponses.push({
          cardId: card.id,
          rightCount: 0,
          leftCount: 0,
          percentage: 0,
          rank: lastRank + currentIndex,
        });
      });

      return rankedResponses;
    };

    const responsesWithRank = prepareSwipeQuestionResult(responses);

    setSortedResponsesWithRank(responsesWithRank);
    setUpdatedSortedResponsesWithRank(responsesWithRank);
  }, [cards, responses]);

  useEffect(() => {
    const prepareCardView = (responses: SwipeQuestionFinishDataWithRank[]) => {
      return cards.map(card => responses.find(res => res.cardId === card.id)!);
    };

    if (currentView === ChartView.CHRONOLOGICAL) {
      const response = prepareCardView(sortedResponsesWithRank);
      if (order === SortOrder.ASC) {
        setUpdatedSortedResponsesWithRank([...response].reverse());
      } else {
        setUpdatedSortedResponsesWithRank(response);
      }
    } else {
      if (order === SortOrder.ASC) {
        setUpdatedSortedResponsesWithRank(
          [...sortedResponsesWithRank].reverse(),
        );
      } else {
        setUpdatedSortedResponsesWithRank(sortedResponsesWithRank);
      }
    }
  }, [cards, currentView, order, sortedResponsesWithRank]);

  const prepareDataForBar = (cardId: string) => {
    const responseForCard = updatedSortedResponsesWithRank.find(
      response => response.cardId === cardId,
    );
    const rightCount = Number(responseForCard?.rightCount) || 0;
    const leftCount = Number(responseForCard?.leftCount) || 0;
    const rightSidePercentage = getPercentage(rightCount, leftCount);
    const leftSidePercentage = getPercentage(leftCount, rightCount);
    return {
      left: leftSidePercentage,
      right: rightSidePercentage,
      rightCount,
      leftCount,
      rank: Number(responseForCard?.rank || 0),
    };
  };

  const getStyleForRankWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRankForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.lastRankForEmptyCard}`;
    } else {
      return `${styles.cardRankForEmptyCard}`;
    }
  };

  const getStyleForRankWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRank}`;
    } else if (rank === lastRank) {
      return `${styles.lastRank}`;
    } else {
      return `${styles.cardRank}`;
    }
  };

  const getStyleForCardWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.cardImagePic} ${styles.cardBorder}`;
    } else if (rank === lastRank) {
      return `${styles.cardImagePic} ${styles.lastCardBorder}`;
    } else {
      return `${styles.cardImagePic}`;
    }
  };
  const getStyleForCardWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.emptyCardImage} ${styles.cardBorderForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.emptyCardImage} ${styles.lastCardBorderForEmptyCard}`;
    } else {
      return `${styles.emptyCardImage}`;
    }
  };

  const displayRank = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <div className={getStyleForRankWithImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        ) : (
          <div className={getStyleForRankWithoutImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        )}
      </>
    );
  };

  const displayCardImage = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              height={80}
              width={64}
              className={getStyleForCardWithImage(rank, lastRank)}
              alt="card pic"
            />
          </>
        ) : (
          <div className={getStyleForCardWithoutImage(rank, lastRank)}></div>
        )}
      </>
    );
  };

  const displayBars = (left: number, right: number) => {
    return (
      <div className={styles.bar}>
        <div
          style={{ width: `${left}%`, background: leftSideColor }}
          className={
            left === 100 ? styles.borderRadius : styles.leftSideBorderRadius
          }
        ></div>
        <div
          style={{ width: `${right}%`, background: rightSideColor }}
          className={
            right === 100 ? styles.borderRadius : styles.rightSideBorderRadius
          }
        ></div>
      </div>
    );
  };

  const getCardRow = (cardId: string) => {
    const card = swipeQuestion.cards.find(card => card.id === cardId);
    const { left, right, leftCount, rightCount, rank } = prepareDataForBar(
      card!.id,
    );

    const imageUrl = card?.image?.url;
    return (
      <div className={customCardRowClass ? customCardRowClass : styles.cardRow}>
        <div className={styles.leftSide}>
          {rank === 1 && (
            <img src={ribbonSvg} className={styles.ribbonImg} alt="rank" />
          )}
          <div className={styles.cardImage}>
            {displayRank(imageUrl, rank, lastRank)}

            {displayCardImage(imageUrl, rank, lastRank)}
          </div>
          <div
            className={rank === 1 ? styles.boldCardText : styles.cardText}
            data-testid="card title"
          >
            {card?.title || 'Untitled'}
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.leftAndRightTextRow}>
            <div style={{ color: leftSideColor }}>{leftCount}</div>
            <div style={{ color: rightSideColor }}>{rightCount}</div>
          </div>
          {displayBars(left, right)}
        </div>
      </div>
    );
  };

  const changeOrder = (sortOrder: SortOrder) => {
    setOrder(sortOrder);
  };

  const changeView = (view: ChartView) => {
    if (view === currentView) {
      return;
    }
    setCurrentView(view);
  };

  const topRow = () => {
    return (
      <div className={styles.header}>
        <div
          className={
            customHeaderRowClass ? customHeaderRowClass : styles.headerRow
          }
        >
          <div className={styles.leftSideForHeader}>
            <div className={styles.toggle}>
              <div
                className={
                  currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
                    ? styles.activeViewForResponse
                    : styles.activeViewForCard
                }
              >
                <LpNewToolTip
                  textOnHover={
                    currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
                      ? 'Sort by right swipes'
                      : 'Sort by card order'
                  }
                  showToolTip={true}
                  toolTipTextCustomClass={styles.toolTipTextCustomClass}
                >
                  {currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE ? (
                    <img src={whiteRibbonSvg} alt="active toggle responses" />
                  ) : (
                    <img src={whiteClockSvg} alt="active toggle cards" />
                  )}
                </LpNewToolTip>
              </div>

              <LpNewToolTip
                textOnHover="Sort by right swipes"
                showToolTip={
                  currentView !== ChartView.RIGHT_SWIPES_RESPONSE_RATE
                }
                containerCustomClass={styles.tooltipContainerClass}
                toolTipTextCustomClass={styles.toolTipTextCustomImageClass}
              >
                <img
                  height={16}
                  width={16}
                  src={greyRibbonSvg}
                  onClick={() =>
                    changeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)
                  }
                  alt="disable toggle responses"
                  className={styles.icon}
                />
              </LpNewToolTip>

              <LpNewToolTip
                textOnHover="Sort by card order"
                showToolTip={currentView !== ChartView.CHRONOLOGICAL}
                containerCustomClass={styles.tooltipContainerClass}
                toolTipTextCustomClass={styles.toolTipTextCardOrderCustomClass}
              >
                <img
                  height={16}
                  width={16}
                  src={clockSvg}
                  onClick={() => changeView(ChartView.CHRONOLOGICAL)}
                  alt="disable toggle cards"
                  className={styles.icon}
                />
              </LpNewToolTip>
            </div>
            <LpNewToolTip
              textOnHover={'Reverse order'}
              showToolTip={true}
              toolTipTextCustomClass={styles.tooltipTextForSortIcon}
            >
              {order === SortOrder.DESC ? (
                <img
                  src={descendingSvg}
                  onClick={() => changeOrder(SortOrder.ASC)}
                  alt="desc order"
                  className={styles.icon}
                />
              ) : (
                <img
                  src={ascendingSvg}
                  onClick={() => changeOrder(SortOrder.DESC)}
                  alt="asc order"
                  className={styles.icon}
                />
              )}
            </LpNewToolTip>
          </div>
          <div className={styles.rightSideForHeader}>
            <div className={styles.swipeOptionText}>
              <LpNewToolTip
                textOnHover={leftSideText}
                showToolTip={true}
                toolTipMarkerPosition={ToolTipMarkerPositionEnum.LEFT}
                toolTipTextCustomClass={styles.customToolTipForLeftClass}
              >
                <img
                  src={swipeLeftSvg}
                  className={styles.leftSideImage}
                  alt="swipe left"
                />
                <span className={styles.leftSideText}> {leftSideText}</span>
              </LpNewToolTip>
            </div>
            <div className={styles.swipeOptionText}>
              <LpNewToolTip
                textOnHover={rightSideText}
                showToolTip={true}
                toolTipMarkerPosition={ToolTipMarkerPositionEnum.RIGHT}
                toolTipTextCustomClass={styles.customToolTipClass}
              >
                <span className={styles.rightSideText}> {rightSideText}</span>
                <img
                  src={swipeRightSvg}
                  className={styles.rightSideImage}
                  alt="swipe right"
                />
              </LpNewToolTip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.container} ${customContainerClass || ''}`}
      data-testid="swipe question finished"
    >
      <>{topRow()}</>
      <div
        className={`${styles.innerContainer} ${
          isReportScreen ? styles.innerContainerForReport : ''
        }`}
      >
        {updatedSortedResponsesWithRank.map(response => {
          return <div key={response.cardId}>{getCardRow(response.cardId)}</div>;
        })}
      </div>
    </div>
  );
};

const getPercentage = (value: number, remainingValue: number) => {
  const total = value + remainingValue;
  if (value === 0) {
    return 0;
  }
  const percentage = (value * 100) / total;
  return percentage;
};
